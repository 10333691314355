<template>
	<div class="form">
		<div class="form_center">
			<img src="../../assets/img/welimg.png" class="login_logo" alt="">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
				<el-form-item :label="$t('EMAILPHONE')" prop="account">					
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.account"></el-input> -->
						<div>{{ruleForm.account}}</div>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('task.MM')" prop="password">
					<el-input v-model="ruleForm.password" show-password :placeholder="$t('PWDRULE')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('QDMM')" prop="repassword">
					<el-input v-model="ruleForm.repassword" show-password ></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('my_msg.CSRQ')" prop="birthday">
					<el-date-picker
					      v-model="ruleForm.birthday"
					      type="date"
						  value-format="yyyy-MM-dd"
					      :placeholder="$t('QXZ')">>
					    </el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('my_msg.NL')" prop="age">
					<el-input v-model="ruleForm.age" readonly></el-input>
				</el-form-item>
				<el-form-item :label="$t('AGEOPEN')" prop="open_age_method" >
					<el-radio-group v-model="ruleForm.open_age_method">
						<el-radio :label="item.data_code/1" v-for="item in openAge" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item :label="$t('my_msg.ZYBS')" prop="profession">
					<el-input v-model="ruleForm.profession" ></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.YWNL')" prop="skills">
					<el-tag	v-for="(tag,index) in ruleForm.skills" :key="tag" @close="ruleForm.skills.splice(index,1)" closable effect="plain" style="margin-right: 10px;">{{tag}}</el-tag>
					<!-- <el-cascader @change="selectSkill" v-model="ruleForm.skills" :options="skillList" :show-all-levels="false" :props="{multiple:false,value:'label'}" style="width: 300px;"></el-cascader> -->
					<!-- <el-dropdown  @command="selectSkill" :hide-on-click="false" >
					  <el-button type="primary" size="mini">
					    {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
					  </el-button>
					  <el-dropdown-menu slot="dropdown">
						<template v-for="(item,index) in skillList" >
							<template v-if="item.children.length>0">
							<el-dropdown-item disabled :divided="index>0">{{item.label}}</el-dropdown-item>
							<el-dropdown-item :command="sitem.label" v-for="(sitem,sindex) in item.children" :key="sitem.value">{{sitem.label}}</el-dropdown-item>
							</template>
						</template>
					  </el-dropdown-menu>
					</el-dropdown> -->
					<el-button type="primary" size="mini" @click="openSkill">
					  {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('my_msg.XB')" prop="gender">
					<el-radio-group v-model="ruleForm.gender">
						<el-radio label="man"><!-- 男性 -->{{$t('my_msg.MAN')}}</el-radio>
						<el-radio label="female"><!-- 女性 -->{{$t('my_msg.WOMAN')}}</el-radio>
						<!-- <el-radio label="none">{{$t('my_msg.BXHD')}}</el-radio> -->
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item :label="$t('SEXOPEN')"  >
					<el-radio-group v-model="ruleForm.open_gender_method">
						<el-radio :label="item.data_code/1" v-for="item in openGender" :key="item.data_code">{{item.data_value}}</el-radio>
						
					</el-radio-group>
				</el-form-item> -->
				<el-form-item :label="'yours'+$t('my_msg.NC')" prop="nickname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.nickname"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.ZSXM')" prop="realname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.realname" :placeholder="$t('NAMETIP')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.ENXM')" prop="en_name">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.en_name" :placeholder="$t('QSR')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.LXFS')" prop="contact_info">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.contact_info"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.YZBM')" prop="postcode">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.postcode"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.DDFX')" prop="cityname">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.name"></el-input> -->
						<!-- <template v-if="lang=='zh'">
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'name',value:'name'}" :placeholder="$t('QXZ')"></el-cascader>
						</template>
						<template v-else> -->
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'label',value:'label'}" :placeholder="$t('QXZ')"></el-cascader>
						<!-- </template> -->
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('my_msg.DWDZ')" prop="location">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.location">							
						</el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.XXDD')" prop="address">
					<div style="display: flex;align-items: center;">
						<el-input type="textarea" v-model="ruleForm.address" :placeholder="$t('ADRTIP')"></el-input>
						
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('RJYZM')" prop="captcha_code">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.captcha_code" placeholder=""></el-input>
						
						
						<div v-html="imgcode" @click="getImgcode" style="line-height: 1.2;margin-left: 10px;"></div>
						<el-button type="primary" size="mini" style="margin-left: 10px;" @click="getImgcode">{{$t('HYZ')}}</el-button>
					</div>
				</el-form-item> -->
				<el-form-item label="" prop="tongyi" style="color:#567BB6;">
					<el-checkbox v-model="agree"></el-checkbox>
					<span style="color: #f00;cursor: pointer;" @click="showtiaokuan=true"><!-- 使用条款 -->{{$t('header.SYTK')}}</span>
					 <!-- 和 -->{{$t('AND')}}
					<span style="color: #f00;cursor: pointer;" @click="showyinsi=true"><!-- 个人信息保护政策 -->{{$t('GRXXBHZC')}}</span> <!-- 我同意 -->{{$t('IAGREE')}}
					
				</el-form-item>
				
				
				<el-form-item>
					<el-button type="primary" @click="submitForm"><!-- 确认注册 -->{{$t('task.QDZC')}}</el-button>
					<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<skills ref="skills"></skills>
		<el-dialog
		  :title="$t('header.YSZC')"
		  :visible.sync="showyinsi"
		  width="50%"
		  top="50px"
		  >
		  <div v-html="yinsi" class="yscont"></div>
		</el-dialog>
		<el-dialog
		  :title="$t('header.SYTK')"
		  :visible.sync="showtiaokuan"
		  width="50%"
		  top="50px"
		  >
		  <div v-html="tiaokuan" class="yscont"></div>
		</el-dialog>
	</div>
</template>

<script>
	import skills from '@/components/skills.vue';
	export default {
		components: {
			
			skills
		},
		data() {
			return {
				showyinsi:false,
				yinsi:'',
				showtiaokuan:false,
				tiaokuan:'',
				imgcode:'',
				citydata:{},//省市区数据
				
				agree:false,
				skillList:[],//业务技能数据
				openAge:[],//公开年龄
				openGender:[],//公开性别
				ruleForm: {
					sign:'',
					account:'',
					password:'',
					repassword:'',
					birthday:'',
					age:'',
					open_age_method:0,
					profession: '',
					skills:[],
					nickname: '',
					realname: '',
					en_name: '',
					gender:'man',
					contact_info: '',
					postcode: '',
					//country: '',
					province: '',
					city: '',
					area: '',
					location: '',
					address: '',
					open_gender_method:0,
					open_name_method:0,
					captcha_code: '',
					cityname:[],
				},
				rules: {
					account: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					password: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					repassword: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					birthday: [{required: true,trigger: 'blur',message:this.$t('QXZ')}],
					age: [{required: true,trigger: 'change',message:this.$t('QSR')}],
					skills: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					profession: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					nickname: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					realname: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					en_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					contact_info: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					postcode: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					address: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					captcha_code: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					cityname: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
				}
			};
		},
		created() {
			this.getCitys();
			// if(this.lang=='jp'){
			// 	this.getCitys();
			// }else{
			// 	this.citydata=require('@/assets/js/city.json'); // 直接引入json文件;
			// }
			this.ruleForm.account=this.$route.query.account;
			this.ruleForm.sign=this.$route.query.sign;
			this.getImgcode();
			//this.getSkill();
			this.getUnit();
			this.getInfo();
			
		},
		watch:{
			'ruleForm.birthday':{
				handler:function(nval,oval){
					if(nval){
						this.ruleForm.age=this.$util.getAge(nval);
					}else{
						this.ruleForm.age='';
					}
				}
			}
		},
		mounted(){
			
			//this.getLocation();
			    
		},
		methods:{
			async getInfo(){
				let res=await this.$request.get('/api/sysdict/getProtectionPolicy');
				this.yinsi=res.data;
				let res2=await this.$request.get('/api/sysdict/getTermsOfUse');
				this.tiaokuan=res2.data;
			},
			async openSkill(){
				let res=await this.$refs.skills.open(this.ruleForm.skills);
				this.ruleForm.skills=res;
			},
			getLocation(){
				let _this=this;
				const geolocation = new BMap.Geolocation();
				geolocation.getCurrentPosition(function(r) {
					if (this.getStatus() == BMAP_STATUS_SUCCESS) {					
					  let myGeo = new BMap.Geocoder();
					  myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result) {
						
						if (result) {
						  
						  console.log('定位',result);
						  _this.ruleForm.location=result.address;
						}
					  });
					} else {
					  //_this.$message.error('failed' + this.getStatus());
					}
				});
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.openAge=res.data.open_age_method.data;
				this.openGender=res.data.open_gender_method.data;
				
				
				
				
			},
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				this.skillList=res.data.records;
			},
			//选择业务技能
			selectSkill(val){
				// console.log(val);
				
				if(this.ruleForm.skills.indexOf(val)==-1){
					this.ruleForm.skills.push(val);
				}
				// let vals=[];
				// for (var i = 0; i < val.length; i++) {
				// 	vals.push(val[i][val[i].length-1]);
				// }
				
				// this.ruleForm.skills=vals;
				console.log(this.ruleForm.skills);
			},
			//获取省市区数据
			async getCitys(){
				let res=await this.$request.get('/api/sysdict/getJapanCityCode');
				this.citydata=res.data.records;
			},
			//选择省市区
			selectCity(val){
				console.log(val);
				this.ruleForm.province=val[0];
				this.ruleForm.city=val[1];
				if(val.length>2){
					this.ruleForm.area=val[2];
				}else{
					this.ruleForm.area='';
				}
				console.log(this.ruleForm);
				//this.$refs.ruleForm.$emit('el.form.change');
			},
			//获取图形验证码
			async getImgcode(){
				let res=await this.$request.get('/api/custauth/captcha',{height:40},{isimg:1});
				this.imgcode=res;
			},
			//提交注册
			async submitForm(){
				// console.log(this.ruleForm);
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				delete data.cityname;
				//console.log(data);
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {
					if(this.agree==false){
						this.$message({
							message:this.$t('YDTYZC'),
							type:'error'
						});
						return;
					}
					this.$request.post('/api/custauth/register',data).then((res)=>{
						this.$message({
							message:res.message,
							type:'success'
						});
						
						this.$local.set('t_k',res.data);
						this.$emit('login',{user_role:0});
						this.$request.reg({userid:this.ruleForm.user_id,nickname:this.ruleForm.nickname,faceurl:''});
						this.$router.push({path:'/home'});
						
					}).catch(()=>{
						this.getImgcode();
					});					 
				  } else {
					// this.$message({
					// 	message:this.$t('QTXWZXX'),
					// 	type:'warning'
					// });
					
					// return false;
				  }
				});
			}
		}
	};
</script>

<style lang="less" >
	.yscont{line-height: revert;
		p,h1,h2,h3,h4,h5,h6{ margin: revert;padding:revert ;line-height: initial; }
		strong,h1,h2,h3,h4,h5,h6{font: revert;}
	}	
	//.el-input-group__append{background: #fff;left: -1px;position: relative;z-index: 2; padding: 0 15px;}
	.form {
		// padding: 30px;
		min-height: 100%;
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.el-form{width: 640px;}
		}
		.login_logo {
			width: 389px;
			
			margin-bottom: 30px;
		}

	}
</style>
